<script lang="ts" setup>
import DocumentsFolderSelectorList from './DocumentsFolderSelectorList.vue'
import type { Folder } from '~/types'
import type { ParametersGetAllUnpaginated } from '~/composables/api/folder/useFetchFolder'
import type { Workspace } from '~/types/auth'

const props = withDefaults(defineProps<{
  selectedFolder?: Folder
  exclude?: Array<App.Data.Folder.FolderData['id']>
  requiredPermissions?: Array<App.Internals.Enums.FolderPermission>
  targetWorkspace?: Workspace
}>(), {
  exclude: () => [],
  requiredPermissions: () => [],
})

const emit = defineEmits<{
  'folder-clicked': [folderId: Folder['id'] | undefined]
  select: [folderId: Folder['id']]
}>()

const list = ref<InstanceType<typeof DocumentsFolderSelectorList>>()

const activeRootFolderId = computed(() => props.targetWorkspace ? props.targetWorkspace.attributes.root_folder_id : rootFolderId.value)

const params = computed<ParametersGetAllUnpaginated>(() => ({
  'filter[parent_id]': props.selectedFolder?.id || activeRootFolderId.value!,
  sort: ['name'],
}))

const { isCurrentUserAdmin: isCurrentUserAdminForActiveWorkspace } = useAuth()

const isCurrentUserAdmin = computed(() =>
  props.targetWorkspace ? props.targetWorkspace?.relationships?.role?.data.attributes.type === 'admin' : isCurrentUserAdminForActiveWorkspace.value)

const shouldUseSharedWithMeQuery = computed(() => !props.selectedFolder && !isCurrentUserAdmin.value)

const {
  data: folderData,
  refetch,
  isPending: isFoldersQueryPending,
  isError: isErrorAllFolders,
} = useQueryFolder.allUnpaginated({
  fieldsToInclude: ['has_children', 'parent', 'breadcrumb', 'own_permissions'],
  params,
  workspaceId: props.targetWorkspace?.id,
  useQueryOptions: {
    enabled: () => !shouldUseSharedWithMeQuery.value,
  },
})

const {
  data: foldersSharedWithMeData,
  isError: isErrorFoldersSharedWithMe,
  isPending: isSharedWithMeQueryPending,
} = useQueryFolder.sharedWithMeUnpaginated({
  workspaceId: props.targetWorkspace?.id,
  fieldsToInclude: ['has_children', 'parent', 'breadcrumb', 'own_permissions'],
  params: {
    sort: ['name'],
  },
  useQueryOptions: {
    enabled: () => !isCurrentUserAdmin.value,
  },
})

const { t } = useI18n()
const { danger } = useToast()

watch([isErrorAllFolders, isErrorFoldersSharedWithMe], () => {
  if (isErrorAllFolders.value || isErrorFoldersSharedWithMe.value)
    danger(t('unknown-error'))
})

const isPending = computed(() =>
  shouldUseSharedWithMeQuery.value
    ? isSharedWithMeQueryPending.value
    : isFoldersQueryPending.value,
)
const isPendingDelayed = refDelayed(isPending, false)

const folders = computed(() =>
  shouldUseSharedWithMeQuery.value
    ? foldersSharedWithMeData.value?.data || []
    : folderData.value?.data || [],
)

const filteredFolders = computed(() => {
  if (!props.exclude.length)
    return folders.value

  return folders.value.filter(f => !props.exclude.includes(f.id))
})

const isEmpty = computed(() =>
  !isPending.value
  && !filteredFolders.value.length
  && !isErrorAllFolders.value
  && !isErrorFoldersSharedWithMe.value,
)

const reloadListing = (folder: Folder) => {
  refetch()
  list.value?.focusFolder(folder.id)
}

const breadcrumbsToUse = computed<App.Data.Folder.BreadcrumbData[]>(() => {
  if (isCurrentUserAdmin.value) {
    return props.selectedFolder?.breadcrumb
      || [{ id: activeRootFolderId.value || '', name: 'Docue', depth: 0 } as App.Data.Folder.BreadcrumbData]
  }

  const idsOfSharedFolders = foldersSharedWithMeData.value?.data.map(({ id }) => id)

  const indexOfFirstSharedFolderInBreadCrumb
  = [...props.selectedFolder?.breadcrumb || []].reverse().findIndex(({ id }) => idsOfSharedFolders?.includes(id))

  return [
    {
      id: 'shared-with-me',
      name: t('documents.folder-selector.shared-with-me'),
      depth: Number.POSITIVE_INFINITY,
    } as App.Data.Folder.BreadcrumbData,
    ...(
      indexOfFirstSharedFolderInBreadCrumb !== undefined && ~indexOfFirstSharedFolderInBreadCrumb
        ? [...props.selectedFolder?.breadcrumb || []]
            .reverse()
            .slice(indexOfFirstSharedFolderInBreadCrumb)
            .reverse()
        : []
    ),
  ]
})
</script>

<template>
  <div
    v-if="!(isEmpty && shouldUseSharedWithMeQuery)"
    class="px-3.5 py-2.5"
  >
    <DocumentsBreadcrumbs
      #default="{ crumb }"
      :breadcrumbs="breadcrumbsToUse"
      :current-folder="selectedFolder"
      class="text-sm"
      @crumb-clicked="(crumb) => emit('folder-clicked', crumb.id === 'shared-with-me' ? undefined : crumb.id)"
    >
      <span
        v-if="crumb.id === 'shared-with-me' && !selectedFolder"
        class="shrink-0 truncate text-gray-600"
      >
        {{ crumb.text }}
      </span>
      <span
        v-else-if="selectedFolder && crumb.id === selectedFolder.id"
        class="line-clamp-1 min-w-0 break-all font-medium text-gray-800"
        :title="crumb.text"
      >
        {{ selectedFolder.name }}
      </span>
      <button
        v-else
        class="max-w-[20ch] shrink-0 truncate text-gray-600 hover:underline"
        :title="crumb.text"
        @click="() => emit('folder-clicked', crumb.id === 'shared-with-me' ? undefined : crumb.id)"
      >
        {{ crumb.text }}
      </button>
    </DocumentsBreadcrumbs>
  </div>
  <div
    v-if="isPendingDelayed"
    class="flex h-40 items-center justify-center"
  >
    <DTLoaderDots />
  </div>
  <template v-else>
    <div
      v-if="isEmpty && shouldUseSharedWithMeQuery"
      class="px-3.5 py-2.5 text-gray-400"
    >
      {{ t('documents.folder-selector.no-folders-available') }}
    </div>
    <DocumentsFolderSelectorList
      v-else
      ref="list"
      :folders="filteredFolders"
      :required-permissions="requiredPermissions"
      :active-root-folder-id="activeRootFolderId"
      @folder-clicked="(folder) => emit('folder-clicked', folder.id)"
      @select="(folder) => emit('select', folder.id)"
    />
    <DocumentsFolderSelectorCreateFolder
      v-if="selectedFolder"
      :parent-folder="selectedFolder"
      :workspace-id="targetWorkspace?.id"
      @folder-created="reloadListing"
    />
  </template>
</template>
