<script lang="ts" setup>
import LineAlertCircle from '@docue/docue-ui-v2/icons/LineAlertCircle.vue'
import LinePlus from '@docue/docue-ui-v2/icons/LinePlus.vue'
import LineX from '@docue/docue-ui-v2/icons/LineX.vue'
import type { Folder } from '~/types'

const props = defineProps<{
  parentFolder: Folder
  workspaceId?: string
}>()

const emit = defineEmits<{
  folderCreated: [folder: Folder]
}>()

const { t } = useI18n()

const { activeWorkspaceId } = useAuth()
const input = ref<HTMLInputElement>()
const folderName = ref('')
const isFormVisible = ref(false)
const formId = readonly(ref(`${getCurrentInstance()?.uid}-new-folder-form`))
const error = ref(false)

const canWrite = computed(() => !!props.parentFolder.own_permissions?.can_write)

watch(isFormVisible, isVisible => isVisible && nextTick(() => input.value!.focus()))

const hideFolderForm = () => {
  isFormVisible.value = false
  folderName.value = ''
}

const {
  isLoading: isFolderCreationPending,
  startLoading,
  stopLoading,
} = useLoadingCount()

const submit = async () => {
  if (isFolderCreationPending.value)
    return

  error.value = false
  startLoading()
  try {
    const created = props.workspaceId && activeWorkspaceId.value !== props.workspaceId
      ? await useApi().createFolder({
        parent_id: props.parentFolder.id,
        name: folderName.value,
      }, props.workspaceId).then(({ data }) => data)
      : await createFolder({
        parent_id: props.parentFolder.id,
        name: folderName.value,
      })

    emit('folderCreated', created)
    hideFolderForm()
  }
  catch (e) {
    console.error(e)
    error.value = true
  }
  finally {
    stopLoading()
  }
}
</script>

<template>
  <div
    class="px-1.5 pt-0.5"
  >
    <button
      v-show="!isFormVisible"
      v-dt-tooltip="!canWrite ? {
        content: t('documents.folder-selector.missing-permission.write'),
        variant: 'dark',
        placement: 'bottom',
        tooltipClass: 'z-[999]',
      } : undefined"
      :aria-expanded="isFormVisible"
      :aria-controls="formId"
      class="
          flex
          w-full
          items-center
          gap-2
          rounded-md
          p-2.5
          pl-3
          text-left
          text-gray-700
          hover:bg-gray-50
          "
      :class="{ '!cursor-not-allowed opacity-[0.43]': !canWrite }"
      :disabled="!canWrite"
      @click="isFormVisible = true"
    >
      <LinePlus class="size-5 text-gray-600" />
      {{ $t('documents.folder-selector.create-folder') }}
    </button>
    <form
      v-if="isFormVisible"
      :id="formId"
      class="flex items-center gap-0.5 px-3"
      @submit.prevent="submit"
    >
      <SvgHelper
        #default="{ attributes }"
        :width="20"
        :height="20"
      >
        <img
          src="~/assets/images/icon-folder-generic.svg"
          v-bind="attributes"
        >
      </SvgHelper>
      <DTTooltip
        v-if="error"
        variant="dark"
        :content="$t('unknown-error')"
        :trigger="['hover', 'focus']"
      >
        <LineAlertCircle
          tabindex="0"
          class="mx-2 size-5 text-red-500"
        />
      </DTTooltip>
      <input
        ref="input"
        v-model="folderName"
        :placeholder="$t('documents.folder-selector.folder-input-placeholder')"
        required
        class="grow rounded px-1.5 py-1"
      >
      <span class="ml-1.5 flex items-center gap-2 py-1.5">
        <DTButton
          :disabled="isFolderCreationPending"
          size="xs"
          type="submit"
        >
          {{ $t('create') }}
          <DTSpinner
            v-if="isFolderCreationPending"
            :size="4"
            :weight="2"
          />
        </DTButton>
        <DTIconButton
          :disabled="isFolderCreationPending"
          size="sm"
          variant="plain"
          @click="hideFolderForm"
        >
          <LineX class="size-5" />
        </DTIconButton>
      </span>
    </form>
  </div>
</template>
