<script lang="ts" setup>
import LineChevronDown from '@docue/docue-ui-v2/icons/LineChevronDown.vue'
import LineChevronUp from '@docue/docue-ui-v2/icons/LineChevronUp.vue'
import LineXClose from '@docue/docue-ui-v2/icons/LineXClose.vue'
import iconFolderRoot from '~/assets/images/icon-folder-root.svg?url'
import iconFolderGeneric from '~/assets/images/icon-folder-generic.svg?url'
import type { Folder } from '~/types'
import type { Workspace } from '~/types/auth'

const props = withDefaults(defineProps<{
  permissions?: Array<App.Internals.Enums.FolderPermission>
  exclude?: Array<App.Data.Folder.FolderData['id']>
  loading?: boolean
  targetWorkspace?: Workspace
}>(), {
  permissions: () => [],
  exclude: () => [],
})

const selectedFolderId = defineModel<Folder['id']>()
const isOpen = ref(false)
const searchQuery = ref('')

const { isCurrentUserAdmin: isCurrentUserAdminInActiveWorkspace } = useAuth()

const isCurrentUserAdmin = computed(() =>
  props.targetWorkspace ? props.targetWorkspace?.relationships?.role?.data.attributes.type === 'admin' : isCurrentUserAdminInActiveWorkspace.value)
const activeRootFolderId = computed(() => props.targetWorkspace ? props.targetWorkspace.attributes.root_folder_id : rootFolderId.value)

const folderIdToUseForFetching = computed(() => selectedFolderId.value || (isCurrentUserAdmin.value ? activeRootFolderId.value : '') || '')

const { data: selectedFolder, isLoading } = useQueryFolder.byId({
  id: folderIdToUseForFetching,
  fieldsToInclude: ['parent.own_permissions', 'breadcrumb', 'own_permissions'],
  workspaceId: props.targetWorkspace?.id,
  useQueryOptions: {
    enabled: () => !!folderIdToUseForFetching.value,
  },
})

const isPending = computed(() => isLoading.value || props.loading)

const isPendingDelayed = refDelayed(isPending, false)

whenever(selectedFolder, ({ id }) => (selectedFolderId.value = id))

whenever(isOpen, () => searchQuery.value = '')

const isFolderPermissionCompliant = computed(() =>
  selectedFolder.value
    ? props.permissions.every(requiredPermission =>
      selectedFolder.value?.own_permissions?.[requiredPermission],
    )
    : true,
)

defineExpose({
  isFolderPermissionCompliant,
  pending: isLoading,
})
</script>

<template>
  <DTPopover
    hide-arrow
    placement="bottom"
    content-size="button-width"
    @open="isOpen = true"
    @close="isOpen = false"
  >
    <template #button>
      <DTPopoverButton
        class="w-full rounded-lg"
        :disabled="isPendingDelayed"
        :class="{
          'cursor-not-allowed': isPendingDelayed,
        }"
      >
        <div
          class="flex w-full items-center gap-2 rounded-lg border px-3.5 py-2.5"
          :class="selectedFolderId && !isFolderPermissionCompliant && !isPending
            ? 'border-red-500'
            : 'border-gray-300 hover:border-gray-800'
          "
        >
          <SvgHelper
            #default="{ attributes }"
            :width="20"
            :height="20"
            class="shrink-0"
          >
            <img
              :src="selectedFolderId && selectedFolderId === activeRootFolderId
                ? iconFolderRoot
                : iconFolderGeneric"
              v-bind="attributes"
            >
          </SvgHelper>
          <span
            v-if="isPendingDelayed"
            class="flex h-6 grow items-center"
          >
            <DTLoaderDots />
          </span>
          <span
            v-else
            class="line-clamp-1 grow break-all text-left"
          >
            {{ selectedFolder?.name ?? $t('documents.folder-selector.select-folder') }}
          </span>
          <span class="shrink-0">
            <component
              :is="isOpen ? LineChevronUp : LineChevronDown"
              class="size-5"
            />
          </span>
        </div>
      </DTPopoverButton>
    </template>

    <template #default="{ close }">
      <div
        v-if="isOpen"
        class="flex flex-col rounded-lg"
      >
        <div class="relative border-b border-b-gray-200">
          <input
            v-model="searchQuery"
            class="
                w-full
                rounded-t-lg
                py-2.5
                pl-3.5
                pr-9
                focus:ring-1
                focus:ring-gray-800
                focus-visible:outline-none
                "
            :placeholder="$t('documents.folder-selector.search-placeholder')"
          >
          <DTIconButton
            v-if="searchQuery"
            size="sm"
            variant="plain"
            class="absolute right-2 top-1/2 -translate-y-1/2"
            @click="searchQuery = ''"
          >
            <LineXClose class="size-4" />
          </DTIconButton>
        </div>
        <div
          class="flex flex-col rounded-b-lg pb-1.5"
        >
          <DocumentsFolderSelectorSearchResult
            v-if="searchQuery"
            :query="searchQuery"
            :target-workspace="targetWorkspace"
            @select="(folder) => {
              selectedFolderId = folder.id
              searchQuery = ''
              close()
            }"
            @folder-clicked="(folder) => {
              selectedFolderId = folder.id
              searchQuery = ''
            }"
          />
          <template v-else>
            <DocumentsFolderSelectorListing
              :exclude="exclude"
              :required-permissions="permissions"
              :selected-folder="selectedFolder"
              :target-workspace="targetWorkspace"
              @folder-clicked="(folderId) => selectedFolderId = folderId"
              @select="(folderId) => {
                selectedFolderId = folderId
                close()
              }"
            />
          </template>
        </div>
      </div>
    </template>
  </DTPopover>
</template>
