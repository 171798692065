<script lang="ts" setup>
import type { Folder } from '~/types'
import type { Workspace } from '~/types/auth'

const props = defineProps<{
  query: string
  targetWorkspace?: Workspace
}>()

const emit = defineEmits<{
  select: [folder: Folder]
  'folder-clicked': [folder: Folder]
}>()

const activeRootFolderId = computed(() => props.targetWorkspace ? props.targetWorkspace.attributes.root_folder_id : rootFolderId.value)

const { data, execute, status } = useLazyAsyncData(async () => {
  if (!props.query)
    return []

  return await useApi().search<Folder>(
    { query: props.query, 'filter[type]': 'folder', workspaceId: props.targetWorkspace?.id },
  ).then(r => r.data)
}, { immediate: false, default: () => [] as Array<Folder> })

watchDebounced(() => props.query, () => execute(), { debounce: 300, immediate: true })

const isPending = computed(() => status.value === 'pending')
</script>

<template>
  <div
    v-if="isPending"
    class="px-3.5 pb-2 pt-3.5"
  >
    <DTLoaderDots />
  </div>
  <div v-else>
    <div class="px-3.5 pb-1.5 pt-3 text-sm text-gray-600">
      {{
        $t('documents.folder-selector.search-result-count', data?.length || 0)
      }}
    </div>
    <DocumentsFolderSelectorList
      :folders="data"
      :active-root-folder-id="activeRootFolderId"
      breadcrumbs-visible
      @select="(folder) => emit('select', folder)"
      @folder-clicked="(folder) => emit('folder-clicked', folder)"
    />
  </div>
</template>
