export const useTouch = () => {
  const { pointerType } = usePointer()

  const isTouchDevice = computed(() =>
    pointerType.value
      ? pointerType.value === 'touch'
      : !!window.ontouchstart || !!navigator.maxTouchPoints,
  )

  return {
    isTouchDevice,
  }
}
