<script lang="ts">
const minHeightOneItemClass = 'min-h-[44px]'
const minHeightFiveAndAHalfItemsClass = 'max-h-[252px]'
</script>
<script lang="ts" setup>
withDefaults(defineProps<{
  folders: App.Data.Folder.FolderData[]
  breadcrumbsVisible?: boolean
  requiredPermissions?: Array<App.Internals.Enums.FolderPermission>
  activeRootFolderId: Maybe<string>
}>(), {
  requiredPermissions: () => [],
})

const emit = defineEmits<{
  'folder-clicked': [folder: App.Data.Folder.FolderData]
  select: [folder: App.Data.Folder.FolderData]
}>()

const folderRefs = ref<Record<string, HTMLElement>>({})

defineExpose({
  focusFolder: (folderId: string) => {
    nextTick(() => {
      folderRefs.value?.[folderId]?.focus()
    })
  },
})


</script>

<template>
  <ul
    class="flex flex-col gap-0.5 overflow-y-auto px-1.5"
    :class="minHeightFiveAndAHalfItemsClass"
    tabindex="-1"
  >
    <DocumentsFolderSelectorListItem
      v-for="folder in folders"
      :key="folder.id"
      :ref="(el) => (folderRefs[folder.id] = el as HTMLElement)"
      :class="minHeightOneItemClass"
      :folder
      :required-permissions="requiredPermissions"
      :breadcrumbs-visible="breadcrumbsVisible"
      :active-root-folder-id="activeRootFolderId"
      tabindex="0"
      @click="emit('folder-clicked', folder)"
      @select="emit('select', folder)"
      @keydown.enter="emit('folder-clicked', folder)"
      @keydown.space.prevent="emit('folder-clicked', folder)"
    />
  </ul>
</template>
