<script lang="ts" setup>
import LineChevronRight from '@docue/docue-ui-v2/icons/LineChevronRight.vue'
import type DTTooltip from '@docue/docue-ui-v2/ui-components/DTTooltip/DTTooltip.vue'
import iconFolderRoot from '~/assets/images/icon-folder-root.svg?url'
import iconFolderGeneric from '~/assets/images/icon-folder-generic.svg?url'

const props = withDefaults(defineProps<{
  folder: App.Data.Folder.FolderData
  breadcrumbsVisible?: boolean
  requiredPermissions?: Array<App.Internals.Enums.FolderPermission>
  activeRootFolderId: Maybe<string>
}>(), {
  requiredPermissions: () => [],
})

const emit = defineEmits<{
  select: [folder: App.Data.Folder.FolderData]
}>()

const { t } = useI18n()
const { isTouchDevice } = useTouch()

const isFolderPermissionCompliant = computed(() =>
  // TODO check if this works and think about seprate component
  props.requiredPermissions.every(requiredPermission =>
    props.folder.own_permissions?.[requiredPermission],
  ))

const tooltipOptions = computed<InstanceType<typeof DTTooltip>['$props'] | undefined>(() => {
  if (isFolderPermissionCompliant.value)
    return

  const getTooltipText = () => {
    if (props.requiredPermissions.includes('can_delete'))
      return t('documents.folder-selector.missing-permission.delete')
    if (props.requiredPermissions.includes('can_write'))
      return t('documents.folder-selector.missing-permission.write')

    return t('documents.folder-selector.missing-permission.read')
  }

  return {
    content: getTooltipText(),
    variant: 'dark',
    placement: 'bottom',
    tooltipClass: 'z-[999]',
  }
},
)
</script>

<template>
  <li
    class="
        group
        flex
        w-full
        cursor-pointer
        items-center
        gap-2
        rounded-md
        px-3
        py-2
        text-left
        text-gray-700
        hover:bg-gray-50
        "
  >
    <SvgHelper
      #default="{ attributes }"
      :width="20"
      :height="20"
      class="pointer-events-none shrink-0 select-none"
    >
      <img
        :src="
          folder.id === activeRootFolderId
            ? iconFolderRoot
            : iconFolderGeneric"
        v-bind="attributes"
      >
    </SvgHelper>
    <div class="flex grow flex-col">
      <span class="line-clamp-1 break-all">{{ folder.name }}</span>
      <DocumentsBreadcrumbs
        v-if="breadcrumbsVisible"
        :breadcrumbs="folder.breadcrumb"
        class="text-xs font-normal text-gray-500"
      />
    </div>
    <DTButton
      v-dt-tooltip="tooltipOptions"
      size="xs"
      class="-my-0.5 mx-0.5"
      :class="{
        'hidden group-hover:block': !isTouchDevice,
      }"
      :disabled="!isFolderPermissionCompliant"
      @click.stop="emit('select', folder)"
    >
      {{ t('select') }}
    </DTButton>
    <span class="pointer-events-none ml-auto mr-0 shrink-0 select-none">
      <LineChevronRight
        class="size-5 text-gray-600"
      />
    </span>
  </li>
</template>
